<template>
  <!-- Error page-->
  <b-card>
    <div class="misc-wrapper">
      <div class="p-2 p-sm-3">
        <div class="payment-status">
          <h2 class="mb-1">
            Payment Error:
          </h2>
          <h2
            class="mb-1"
          >
            <strong class="error">{{ customer.paymentStatus }}</strong> sorry, something went wrong.
          </h2>
        </div>
        <p class="text-max-width">
          Something went wrong with your payment.
          please get in contact with us and reference your customer ID:
        </p>
        <p>
          {{ customer.id }}
        </p>
        <a
          href="#"
        >contact us.</a>
      </div>
    </div>
  </b-card>
  <!-- / Error page-->
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import useStripe from '@/composables/stripe/useStripe'

export default {
  components: {
    BCard,
  },
  setup() {
    const { getStripeSession, customerSession: customer } = useStripe()

    const urlParams = computed(() => new URLSearchParams(decodeURI(window.location.search)))
    const sessionId = computed(() => urlParams.value.get('session_id'))

    if (sessionId.value) {
      getStripeSession(sessionId.value)
    }

    return {
      getStripeSession,
      customer,
    }
  },
}
</script>

<style lang="scss">
.error-img {
  border-radius: 1rem;
  max-width: 520px;
}
.text-max-width {
  max-width: 520px;
}

.payment-status {
  display: flex;
  gap: 1rem;
  .error {
    color: red;
  }
  .paid {
    color: green;
  }
}
</style>
